<template>
  <div class="tabs">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="字典配置" name="first">
        <Dict v-if="activeName=='first'"></Dict>
      </el-tab-pane>
      <!-- <el-tab-pane label="巡检周期配置" name="second">
        <Cycle v-if="activeName=='second'"></Cycle>
      </el-tab-pane>
      <el-tab-pane label="采集项配置" name="third">
        <collec v-if="activeName=='third'"></collec>
      </el-tab-pane>
      <el-tab-pane label="采集模板配置" name="fourth">
         <sample v-if="activeName=='fourth'"></sample>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import Dict from './dict.vue'
export default {
  name: 'Index',
  components: { Dict},
  data() {
    return {
      activeName: 'first',
      options:[],
      value:''
    }
  },
  mounted() {
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  // padding: 10px;
}
.tabs{
  margin: 10px;
  background: #ffffff;
  border-radius: 6px;
  padding:0 20px 10px;
}
/deep/.el-tabs__header{
  margin-bottom: 00px;
}
</style>
